import { connect } from 'react-redux';
import LayoutItem from '../../components/workflow/LayoutItem';
import { getTranslate, setActiveLanguage } from 'react-localize-redux';
import { toJS } from '../to-js';
import {
  updateItemPayload,
  resolveRules,
  imgOcrData,
  changeTimerIndex,
  webhookCall,
  readAll,
  getfindbyfieldname
} from '../../actions/workflow-item';
import { getCustomIcons ,getCompanyDetails} from '../../actions/company';
import { base64Upload } from '../../actions/uploader';
import * as validations from '../../components/workflow/validations/index';
import { logout } from '../../actions/user';
import { getModelByNamePromise } from '../../actions/workflows';

const mapStateToProps = (state, ownProps) => {
  const { layoutItem, itemIndex, workflow, container : {properties : {colLayoutConfig}}, columnIndex, additionalModelData} = ownProps;
  if (!layoutItem) return {};
  const colOperation = colLayoutConfig && colLayoutConfig[`colOperation-${columnIndex}`];
  const { itemProperties } = layoutItem;
  const { label, placeholder,info } = itemProperties;
  const classProperty = workflow.hasMember && workflow.memberClassProperty !== itemProperties.field.classProperty ? `${workflow.memberClassProperty}[${workflow.memberIndex}].${itemProperties.field.classProperty}` : itemProperties.field.classProperty;
  const collection = itemProperties.field.collection;
  const type = itemProperties.field.type;
  
  
  const isReadOne =
    !colLayoutConfig && workflow.currentItem.operation === 'READONE' ||
    !colLayoutConfig && workflow.currentItem.operation === 'READALL' ||
    colLayoutConfig && colOperation === 'READONE' || 
    colLayoutConfig && colOperation === 'READALL' ;
  const nextState = state.get('next');
  const formValues = ownProps.form ? state.getIn(['form', ownProps.form, 'values']):state.getIn(['form', 'create-update-form-base', 'values']);
  const registeredFields = state.getIn([
    'form',
    ownProps.form,
    'registeredFields',
  ]);
  const value = (ownProps.useActualClassProperty ?  state.getIn(['form', ownProps.form, 'values', classProperty]): state.getIn([
      'save',
      'payload',
      ownProps.layoutItem.itemProperties.field.classProperty,
    ])) || '';
    console.log(value, classProperty)
  const timerIndex = state.getIn(['workflow', 'timerIndex']);
  const companySettings = state.getIn(['company', 'details', 'data', 'companySettings']);
  const companyDetails = state.getIn(['company', 'details']);
  return {
    translate: getTranslate(state.get('locale')),
    label,
    placeholder,
    info,
    layoutItem,
    layoutItemIndex: itemIndex,
    value,
    classProperty,
    collection,
    isReadOne,
    type,
    workflow,
    nextState,
    phoneNumberCountryCode: state.getIn([
      'company',
      'details',
      'data',
      'defaultCountryPhoneCode',
    ]),
    formValues,
    registeredFields,
    timerIndex,
    customIcons: state.getIn(['company', 'icons']),
    timezone: state.getIn(['company', 'details', 'data', 'timezone']),
    useLabelAsPlaceholder: companySettings ? companySettings.get('useLabelAsPlaceholder') : false,
    typeModelCollection: workflow && workflow.collections ? workflow.collections : null,
    availableLanguages : companySettings && companySettings.get('langauges'),
    companyDetails,
    customStyles: state.getIn(['company', 'styleDetails']),
    additionalModelData:additionalModelData?additionalModelData:workflow.workflowData && workflow.workflowData.__ADDITIONAL_MODEL_DATA
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLayoutItemChanged: (key, value) => {
      dispatch(updateItemPayload({ key, value }));
    },
    getValidations: layoutItem => {
      if (layoutItem && layoutItem.itemType === 'MODELPROPERTIES') {
        let itemValidations = [];
        const {
          field,
          minLength,
          regexPattern,
          regexErrorMessage,
		  maxLength,
      isUnique,
      notValidValue,
      validationMsg
        } = layoutItem.itemProperties;
        const { type,collection } = field;
        const { required, step } = field.properties;
        if (minLength) {
          const min = parseInt(minLength);
          if (min === 1) {
            if(type === "BIT") {
              itemValidations.push(validations.isTrue);
            } else if(type === "TYPE" && !collection) {
              itemValidations.push(validations.requiredType);
            } else {
              itemValidations.push(validations.required);
            }
          } else {
            itemValidations.push(validations.minLength(min));
          }
        }

        if(isUnique){
          itemValidations.push(validations.isUnique(false));
        }
        if(notValidValue){
          itemValidations.push(validations.compare(validationMsg));
        }
        if(type == 'PHONE'){
          itemValidations.push(
            validations.phone
          );
        }
		
        if (regexPattern) {
          itemValidations.push(
            validations.regexPattern(regexPattern, regexErrorMessage)
          );
        }
        //TYPE PROPERTIES VALIDATIONS
        required && itemValidations.push(validations.required);

        //FIXME: Rename step to length... Need to have a fixed length validtion

        step && itemValidations.push(validations.fixedLength);
		if (type == 'SMALLTEXT' && maxLength) {
          const max = parseInt(maxLength);
          itemValidations.push(validations.maxLngth(max));
        } else {
			//ITEM TYPE BASED VALIDATIONS
			const _validations = validations.typeValidations[type];
			if (typeof validations !== 'undefined' && Array.isArray(_validations)) {
			  itemValidations = itemValidations.concat(_validations);
			}
		}
        return itemValidations;
      }
    },
    resolveRules: payload => {
      return dispatch(resolveRules(payload));
    },
    getImgOcrData: payload => {
      return dispatch(imgOcrData(payload));
    },
    changeTimerIndex: payload => {
      dispatch(changeTimerIndex(payload));
    },
    getCustomIcons: payload => {
      dispatch(getCustomIcons(payload));
    },
    logout: (history,companyDetails) => {
      dispatch(logout(history,companyDetails));
    },
	  webhookCall: payload => {
      return dispatch(webhookCall(payload));
    },
    getModelByNamePromise: (payload) => {
      return dispatch(getModelByNamePromise(payload));
    },
    readAll: (payload, containerId) => {
      dispatch(readAll(payload, containerId));
    },
    setActiveLanguage : payload => {
      dispatch(setActiveLanguage(payload))
    },
    base64Upload: payload => {
      return dispatch(base64Upload(payload));
    },
    getfindbyfieldname: (payload) => {
      return dispatch(getfindbyfieldname(payload));
  },
  getCompanyDetails: () => {
    return dispatch(getCompanyDetails());
}
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(LayoutItem));
