import {
    START_WORKFLOW,
    START_WORKFLOW_SUCCESS,
    BASE_WORKFLOW_SUCCESS,
    START_WORKFLOW_FAILURE,
    LOAD_BG,
    LOAD_BG_SUCCESS,
    LOAD_BG_FAILURE,
    REDIRECT_TO_DASHBOARD,
    RESET_WORKFLOW_STATE,
    END_WORKFLOW,
    END_WORKFLOW_SUCCESS,
    END_WORKFLOW_FAILURE,
    REFRESH_BASE_WORKFLOW_TRUE,
    REFRESH_BASE_WORKFLOW_FALSE,
	RESET_SORT_PAGINATION,
	SET_SORT_PAGINATION,
    SET_WORKFLOW_FOR_WORKFLOWCARD
} from '../actions/workflows';

import {
    READ_ALL,
    READ_ALL_SUCCESS,
    READ_ALL_ERROR,
    NEXT_ITEM_REQUESTED,
    NEXT_ITEM_REQUESTED_SUCCESS,
    NEXT_ITEM_REQUESTED_FAILURE,
    UPDATE_WORKFLOW_DATA,
    UPDATE_WORKFLOW_CARD_DATA,
	CHANGE_TIMER
} from '../actions/workflow-item';


import {
    READ_ONE,
    READ_ONE_SUCCESS,
    READ_ONE_ERROR
} from '../actions/workflow-item';

import {
    TRIGGER_ACTION_SUBWORKFLOW,
    TRIGGER_ACTION_SUBWORKFLOW_SUCCESS,
    TRIGGER_ACTION_SUBWORKFLOW_FAILURE,
    SET_TID,
    UPDATE_READ_ALL,
    UPDATE_READ_ALL_LOADING
} from '../actions/workflow-actions';

import { Map, List } from 'immutable';

const intitalState = Map({
    isLoading: false,
    baseWorkflow: null,
    modalWorkflow: null,
    sidebarWorkflow: null,
    inlineWorkflows: Map({}),
    tid: null,
    bgBytes: null,
    redirectToDashboard: false,
    refreshBaseWorkflow: false,
	sortPagination: Map({sortField: null,
	order: 'ASCENDING',
	paginationStart: 0, 
	pageSize: 25}),
	timerIndex: 0,
    workflowsForWorkflowCard: Map({})
});

const displayModesMap = {
    DEFAULT: 'baseWorkflow',
    MODAL: 'modalWorkflow',
    SIDEBAR: 'sidebarWorkflow',
    INLINE: 'inlineWorkflows'
};

const clearWorkflowForMode = (displayMode, state) => {
    return state.merge({ [displayModesMap[displayMode]]: null });
}

const addInlineWorkflow = (state, workflow) => {
    let inlineWorkflows = state.get('inlineWorkflows');
    inlineWorkflows = inlineWorkflows.set(`${workflow.workflowModel}`, workflow);
    return state.merge({ inlineWorkflows });
}

const addWorkflowData = (state, action) => {
    const workflow = action.payload;
    const { displayMode } = workflow;
    return state.merge({ [displayModesMap[displayMode]]: workflow, isLoading: false, hasError: false, error: null });
};

const addWorkflow = (state, action) => {
    const workflow = action.payload;
    const { displayMode, workflowEnd, clearWorkflow } = workflow;
    
	const newState = workflowEnd && clearWorkflow !== null ? clearWorkflowForMode(clearWorkflow, state) : state;
    if (displayMode === 'INLINE') {
        return addInlineWorkflow(state, workflow);
    }
    return newState.merge({ [displayModesMap[displayMode]]: workflow, isLoading: false, hasError: false, error: null });
};

export const getWorkflowType = (displayMode) => {
    let workflowType = 'baseWorkflow';
    switch (displayMode) {
        case 'DEFAULT': workflowType = 'baseWorkflow'; break;
        case 'MODAL': workflowType = 'modalWorkflow'; break;
        case 'SIDEBAR': workflowType = 'sidebarWorkflow'; break;
        case 'INLINE': workflowType = 'inlineWorkflows'; break;
        default: workflowType = 'baseWorkflow'; break;
    }
    return workflowType;
}

const createCollectionKey = (state, action) => {
    const { typeId, workflow, containerId, customModelName } = action.payload;
    const { displayMode } = workflow;
    const workflowType = getWorkflowType(displayMode);
    let currentWorkflow = state.get(workflowType); 
    if (!currentWorkflow) return state;
    if (currentWorkflow.get('collections') === null || currentWorkflow.get('collections') === undefined) {
        currentWorkflow = currentWorkflow.set('collections', Map({}));
    }
    const modelProp = customModelName ? customModelName : typeId;
    const existingData = (window.existingData || [])[workflow.workflowModel + (containerId?containerId:'')];
    const _OBJ = { isLoading: true, data: [] };
    // if(existingData) {
    //     currentWorkflow = containerId?currentWorkflow.setIn(['collections', `${typeId}_${containerId}`], existingData):
    //     currentWorkflow.setIn(['collections', `${typeId}`], existingData);
    // } else
        currentWorkflow = containerId?currentWorkflow.setIn(['collections', `${modelProp}_${containerId}`], _OBJ):
        currentWorkflow.setIn(['collections', `${typeId}`], _OBJ);
    return state.set(workflowType, currentWorkflow);
}

const addData = (state, action) => {
    const { data, isBase, typeId, workflow, totalCount, containerId, customModelName, columnId } = action.payload;
    let colId = typeof columnId !== 'undefined' && `col${columnId}`;
    const { displayMode } = workflow;
    const workflowType = getWorkflowType(displayMode);
    let currentWorkflow = state.get(workflowType);
    const _OBJ = { isLoading: false, data, totalCount };
    const modelProp = customModelName ? customModelName : typeId;
    currentWorkflow = containerId? colId ? currentWorkflow.setIn(['collections', `${modelProp}_${containerId}_${columnId}`], _OBJ)
                        :currentWorkflow.setIn(['collections', `${modelProp}_${containerId}`], _OBJ)
                        :currentWorkflow.setIn(['collections', `${modelProp}`], _OBJ);
    if(!window.existingData) window.existingData = [];
    window.existingData[workflow.workflowModel + (containerId?containerId:'')] = _OBJ;
    return state.set(workflowType, currentWorkflow);
}

const updateWorkflowData = (state, payload) => {
    const { workflowData, workflow: { displayMode } } = payload;
    const workflowKey = getWorkflowType(displayMode);
    let currentWorkflow = state.get(workflowKey);
    currentWorkflow = currentWorkflow.set('workflowData', workflowData);
    return state.set(workflowKey, currentWorkflow);
}

const updateWorkflowCardData = (state, payload) => {
    const { workflowCard, workflowCardIndex, typeModelId, containerId, workflow: { displayMode }  } = payload;
    const workflowKey = getWorkflowType(displayMode);
    let currentWorkflow = state.get(workflowKey);
    let collection = containerId?currentWorkflow.getIn(['collections', `${typeModelId}${containerId}`]) : [];
    if(collection && collection.data) {
        collection.data[workflowCardIndex] = workflowCard;
        currentWorkflow = containerId && currentWorkflow.setIn(['collections', `${typeModelId}${containerId}`], collection);
    }
    return state.set(workflowKey, currentWorkflow);
}

const handleError = (state, payload) => {
    const displayMode = payload.displayMode;
    if(displayMode === "MODAL") {
        let modalWorkflow = state.get("modalWorkflow");
        modalWorkflow = modalWorkflow.set("workflowError", {
            hasError: true,
            message: payload.error.message
        })
        return state.merge({ modalWorkflow, isLoading: false })
    } else if(displayMode === "SIDEBAR") {
        let sidebarWorkflow = state.get("sidebarWorkflow");
        sidebarWorkflow = sidebarWorkflow.set("workflowError", {
            hasError: true,
            message: payload.error.message
        })
        return state.merge({ sidebarWorkflow, isLoading: false })
    } else {
        let baseWorkflow = state.get("baseWorkflow");
        if(baseWorkflow) {
            baseWorkflow = baseWorkflow.set("workflowError", {
                hasError: true,
                message: payload.error.message
            })
        }
        return state.merge({ baseWorkflow, isLoading: false })
    }
}

const updateReadAll = (state, payload) => {
    console.log(payload.payload);
    const { wfData, typeModelId, workflow, containerId, columnIndex, tid } = payload.payload;
    const { displayMode } = workflow;
    const workflowType = getWorkflowType(displayMode);
    let currentWorkflow = state.get(workflowType);
    let collection = containerId >= 0 ?currentWorkflow.getIn(['collections', `${typeModelId}_c${containerId}`]) : [];
    if(collection && collection.data) {
        for(let i=0;i<collection.data.length; i++){
            if(collection.data[i].id == tid){
                collection.data[i] = wfData;
            }
        }
        const _OBJ = { isLoading: false, data: collection.data };
        currentWorkflow = containerId >= 0 && currentWorkflow.setIn(['collections', `${typeModelId}_c${containerId}`], _OBJ);
    }
    return state.set(workflowType, currentWorkflow);
}

const updateReadAllLoading = (state, payload) => {
    console.log(payload.payload);
    const { typeModelId, workflow, containerId, columnIndex, tid } = payload.payload;
    const { displayMode } = workflow;
    const workflowType = getWorkflowType(displayMode);
    let currentWorkflow = state.get(workflowType);
    let collection = containerId >= 0 ?currentWorkflow.getIn(['collections', `${typeModelId}_c${containerId}`]) : [];
    const _OBJ = { isLoading: true, data: collection.data };
    currentWorkflow = containerId >= 0 && currentWorkflow.setIn(['collections', `${typeModelId}_c${containerId}`], _OBJ);
    return state.set(workflowType, currentWorkflow);
}


const workflow = (state = intitalState, action = {}) => {
    switch (action.type) {
        case START_WORKFLOW: return state.merge({
            isLoading: true,
            hasError: false,
            error: null
        });

        case START_WORKFLOW_SUCCESS: return addWorkflow(state, action);
        case BASE_WORKFLOW_SUCCESS: return addWorkflowData(state, action);
        //state.merge({ baseWorkflow: action.payload, isLoading: false, hasError: false, error: null });

        case REFRESH_BASE_WORKFLOW_TRUE: return state.merge({ refreshBaseWorkflow: true, baseWorkflow: null, inlineWorkflows: null, tids: null });
        case REFRESH_BASE_WORKFLOW_FALSE: return state.merge({ refreshBaseWorkflow: false });

        case START_WORKFLOW_FAILURE: return state.merge({
            isLoading: false,
            hasError: true,
            error: action.payload
        });

        case NEXT_ITEM_REQUESTED: return state.merge({
            isLoading: true,
            hasError: false,
            error: null
        });

        case NEXT_ITEM_REQUESTED_SUCCESS: return addWorkflow(state, action);
        case END_WORKFLOW_SUCCESS: return addWorkflow(state, action);

        case NEXT_ITEM_REQUESTED_FAILURE: return handleError(state, action.payload)

        case READ_ALL: return createCollectionKey(state, action);
        case READ_ALL_SUCCESS: return addData(state, action);
        case UPDATE_READ_ALL: return updateReadAll(state, action);
        case UPDATE_READ_ALL_LOADING: return updateReadAllLoading(state, action);
        case READ_ONE_SUCCESS: return addData(state, action);

        case TRIGGER_ACTION_SUBWORKFLOW: return state.merge({
            isLoading: true
        });

        case TRIGGER_ACTION_SUBWORKFLOW_SUCCESS: return addWorkflow(state, action);

        case TRIGGER_ACTION_SUBWORKFLOW_FAILURE: return state.merge({
            isLoading: false,
            hasError: true,
            error: action.payload
        });

        case SET_TID: return state.merge({ tid: action.payload });

        case LOAD_BG_SUCCESS: return state.merge({ bgBytes: action.payload });

        case REDIRECT_TO_DASHBOARD: return state.merge({ redirectToDashboard: true });

        case RESET_WORKFLOW_STATE: return intitalState;

        case UPDATE_WORKFLOW_DATA: return updateWorkflowData(state, action.payload);

        case UPDATE_WORKFLOW_CARD_DATA: return updateWorkflowCardData(state, action.payload);
		
		case RESET_SORT_PAGINATION: return state.merge({
            sortPagination: Map({sortField: null,
			order: 'ASCENDING',
			paginationStart: 0, 
			pageSize: 25 })
        });
		
		case SET_SORT_PAGINATION: return state.merge({
            sortPagination: action.payload
        });
		
		case CHANGE_TIMER: return state.merge({ timerIndex: action.payload });
        case SET_WORKFLOW_FOR_WORKFLOWCARD: {
            const currentwf = state.workflowsForWorkflowCard;
            currentwf[action.payload.key] = action.payload.value;
            return state.merge({
                workflowsForWorkflowCard: currentwf
            })
        }

        default: return state;
    }
};

export default workflow;
