import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, Input as InputField, Table as table } from 'reactstrap';
import ActionCell from './helpers/ActionCell';
import * as CellTypes from './helpers/cells/index';
import { WIDTHS } from './helpers/TypeColumnWidths';
import { Alert } from 'reactstrap';
import Measure from 'react-measure';
import NoDataAlertBox from '../../commons/NoDataAlertBox';
import FileDownload from '../FileDownload'
import {
    FileProtectOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Table as Table2, Tag, Image, Tooltip, Statistic, Space, Popover, Button, Input } from 'antd';
import { ModelPropertiesParser, ModelPropertiesParserCal } from '../../commons/modelPropertiesParser';
import DynamicColumnsTable from './TableV2/DynamicTable';
import { calculateColumnsWidth } from './TableV2/DynamicHelper';
import config2 from '../../../config';
import client from '../../../client';
import { analyseEvaluatedText } from '../../commons/evaluatedTextVerfier';
import TextStyleResolver from '../TextStyleResolver/index';
import {
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import Search from './Search';

class VirtualizedTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locationModalOpen: false,
            locationValue: '',
            config: '',
            imageValue: '',
            imageModalOpen: false,
            maxHeight: 800,
            filterTable: null,
            dimensions: {
                width: -1,
                height: -1
            }
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleImageModal = this.toggleImageModal.bind(this)
    }

    toggleModal(value, config) {
        this.setState({ locationModalOpen: !this.state.locationModalOpen, locationValue: value, config: config })
    }
    toggleImageModal(value, config) {
        this.setState({ imageModalOpen: !this.state.imageModalOpen, imageValue: value, config: config })
    }
    componentDidMount() {
        this.setState({ maxHeight: window.innerHeight - 180 });
    }

    componentWillReceiveProps(nextProps) {
        // console.log('nextProps')
    }
    checkColumnsFit(availWidth, columns) {
        let calcWidth = 0;
        columns.map((col) => {
            const { field } = col;
            if (field) {
                const type = field.type;
                const typeWidth = WIDTHS[type] || { width: 100, flexGrow: 0 };
                calcWidth = calcWidth + typeWidth.width;
            }
        });
        return calcWidth < availWidth;
    }

    checkInlineNonSubworkflowAction = (actions) => {
        let hasInlineNonSubWorkflowAction = 0;
        actions && actions.map((action) => {
            hasInlineNonSubWorkflowAction = action.actionProperties.workflow ? action.code === "DeleteActionProcessor" ? hasInlineNonSubWorkflowAction + 1 : hasInlineNonSubWorkflowAction : hasInlineNonSubWorkflowAction + 1;
            if (!hasInlineNonSubWorkflowAction && action.actionProperties.actionName.startsWith("ALLOW_DELETION_GENERATED")) {
                hasInlineNonSubWorkflowAction = hasInlineNonSubWorkflowAction + 1;
            }
        });
        return hasInlineNonSubWorkflowAction;
    }

    checkInlineActions = (actions) => {
        let hasInlineAction = 0;
        actions && actions.map((action) => {
            hasInlineAction = action.actionProperties.systemGenerated ? hasInlineAction : hasInlineAction + 1;
        });
        return hasInlineAction;
    }

    sortByColumn = (classProperty) => {
        this.props.sortByClassProperty && this.props.sortByClassProperty(classProperty);
    }

    onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination);
    };

    filterSearchData = (filterdData) => {
        this.setState({ filterTable: filterdData })
    }

    toggleSelectAll() {
        this.setState({ selectAll: !this.state.selectAll })
    }

    render() {
        const { rows,thmbnailFormatSelection,showThumnailImage, columns, inlineActions, workflow, canActOnMultipleItems, typeModelName, tableStyleConfig, sortByClassProperty, dateformat, menuTypeInlineAction,
            sorting, workflow: { currentItem: { allowDownloadAsPdf, duplicateRowsInOneToMany, operation, name, pageHeaderConfig } }, isReadOneTable, noDataText, noDataTextHeading, companyDetails } = this.props;

        const { allDataDownload, allData, workflow: { currentItem: { layoutProperties } } } = this.props
        const { filterTable } = this.state;
        const { width } = this.state.dimensions;
        if (!rows || !columns) return null;
        if (rows.getSize() === 0) {
            return <NoDataAlertBox typeModelName={typeModelName || workflow.currentItem.typeModel.name} noDataText={noDataText} noDataTextHeading={noDataTextHeading} {...this.props} />
        }
        const windowHeight = window.innerHeight > 300 ? window.innerHeight : 300;
        const { maxHeight } = this.state;
        let selectedTemplate = this.props.selectedTemplate;
        if(selectedTemplate === 'TEMPLATE_THREE'){
            selectedTemplate = 'Template3'
        }
 
        const rowHeight = 50;
        const actionColumnWidth = layoutProperties && layoutProperties.actionColumnWidth ? parseInt(layoutProperties.actionColumnWidth) : 200;
        const tableWidth = width;
        const hasInlineNonSubWorkflowAction = this.checkInlineNonSubworkflowAction(inlineActions);
        const hasInlineActions = this.checkInlineActions(inlineActions);
        const availableContentColumnsWidth = hasInlineActions ? (tableWidth - actionColumnWidth) : tableWidth;
        const numColumns = columns.length;
        const hasExtraSpace = this.checkColumnsFit(availableContentColumnsWidth, columns);
        const RowSelectorCell = CellTypes.ROWSELECTORCELL;
        const fileName = pageHeaderConfig && pageHeaderConfig.isConfigured ? pageHeaderConfig.modelSelector : name;
        // const tableHeight = this.state.dimensions.top ? windowHeight - this.state.dimensions.top - 60 : rowHeight * rows.getSize() + 60;
        const tableHeight = rowHeight * rows.getSize() + 60;
        let allRows = {};
        allRows['_rows'] = allData;


        // TEMPLATE 2 COnfigs

        const bordered = tableStyleConfig && tableStyleConfig.tableBorder
        const title = tableStyleConfig && tableStyleConfig.tableHeading
        const footer = tableStyleConfig && tableStyleConfig.tableFooter
        const pagination = tableStyleConfig && tableStyleConfig.tableCustomPageSize ? parseInt(tableStyleConfig.tableCustomPageSize) : this.props.pageSize
        const enablePagination = tableStyleConfig && tableStyleConfig.enablePagination ? tableStyleConfig.enablePagination : null;
        const scroll = tableStyleConfig && tableStyleConfig.tableHeaderFixHeight ? parseInt(tableStyleConfig.tableHeaderFixHeight) : null
        const fixHeaderEnable = tableStyleConfig && tableStyleConfig.fixHeaderEnable ? tableStyleConfig.fixHeaderEnable : null
        const size = tableStyleConfig && tableStyleConfig.tableSize ? tableStyleConfig.tableSize.value : null
        const topPostion = tableStyleConfig && tableStyleConfig.tablePaginationPosition ? tableStyleConfig.tablePaginationPosition.value : null;
        const tableActionPosition = tableStyleConfig && tableStyleConfig.tableActionPosition ? tableStyleConfig.tableActionPosition.value : null;

        let filterSystemGenratedActions = inlineActions ? inlineActions.filter(action => {
            // console.log(action,"actionaction")
            return !(action.actionProperties.hasOwnProperty('systemGenerated') && action.actionProperties.systemGenerated === true)
        }) : []

        let columnsAntd = [];
        columnsAntd = columnsAntd.concat(columns)

        columnsAntd && columnsAntd.map((column, columnIndex) => {
            const collection = column.field.collection;
            let renderCell = (text, item, index) => (typeof text === 'number' && text === 0 || text) ? <TextStyleResolver toggleImageModal={() => {
                let textTab = text;
                
                if(text && text.startsWith('file:///data/user') && companyDetails && companyDetails.data && companyDetails.data.name) {
                    const split = text.split("/");
                    const imageName = split[split.length - 1];
                    const companyName = companyDetails.data.name.toLowerCase();
                    textTab = `${companyName}/${imageName}`
                }
                this.toggleImageModal(`${config2.assetsBasePath}${textTab}`, column.config.header)
            }} text={text} workflow={workflow}
                column={column}
                rows={rows}
                config={config}
                keyValue={item && item.key}
                actions={inlineActions}
                dateformat={dateformat}
                rowIndex={index} 
                companyDetails={companyDetails}
                /> : config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>

            const { field: { type }, config, config: { header } } = column;
            const columnKey = column.field.classProperty;
            if (type === 'TYPE' || type === 'DROPDOWN') {
                if (columnKey && columnKey.startsWith("$$SELF$$")) {
                    if (columnKey && columnKey.startsWith("$$SELF$$") && config && config.componentShowAs && config.componentShowAs.value === 'number') {
                        const columnNumberConfig = config.columnNumberConfig;
                        renderCell = (text) => text ? <Statistic title={null} value={text} /> : config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>
                        if (columnNumberConfig) {
                            const afterIcon = columnNumberConfig.afterIcon;
                            const suffixIcon = `fa ${afterIcon} ml-1`
                            const beforeIcon = columnNumberConfig.beforeIcon;
                            const prefixIcon = `fa ${beforeIcon} mr-1`
                            const formatter = columnNumberConfig.formatter;
                            const precision = columnNumberConfig.precision;
                            const suffix = columnNumberConfig.suffix;
                            const valueStyle = columnNumberConfig.valueStyle;
                            const fontSize = valueStyle && valueStyle.fontSize;
                            const color = valueStyle && valueStyle.color;

                            renderCell = (text) => text ? <Statistic
                                title={null}
                                value={text}
                                valueStyle={valueStyle ? { fontSize: parseInt(fontSize), color: color ? color : "#000" } : {}}
                                precision={precision}
                                prefix={<i className={prefixIcon} aria-hidden="true"></i>}
                                suffix={<i className={suffixIcon} aria-hidden="true"></i>}
                            /> : config.nullData ? <span>{config.nullData}</span> :config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>
                        }
                    }
                }


                if ((type === 'TYPE' && !columnKey.startsWith("$$SELF$$") && collection)) {
                    // let color = config && config.columnDropdownConfig &&  config.columnDropdownConfig.color? config.columnDropdownConfig.color :'volcano'
                    let colors = ['magenta',
                        'orange',
                        'gold',
                        'lime',
                        'green',
                        'cyan',
                        'blue',
                        'geekblue',
                        'purple']
                    let icon = config && config.columnDropdownConfig && config.columnDropdownConfig.beforeIcon ? config.columnDropdownConfig.beforeIcon : null
                    const prefixIcon = `fa ${icon} mr-1`;
                    const showAsTable = config.showAsTable;

                    renderCell = (text) => {
                        const content = text && text.length > 0 && text.map((value, index) => {
                            return (<div key={index}><Tag key={index} icon={<i className={prefixIcon} aria-hidden="true"></i>} color={colors[index]}>
                                {value}
                            </Tag></div>)
                        })
                        if(showAsTable){
                            const h = text && text.length > 0 ? text[0].split(',') : [];
                            return <table striped bordered className='bg-white' size="sm" >
                            <thead>
                                {
                                    h.map(th=>{
                                        const t = th.split('-')
                                        return <th key={t[0]}>{t[0]}</th>
                                    })
                                }
                            </thead>
                            <tbody>
                                {
                                    text && text.length > 0 && text.map((value, index) => {
                                        let r = value ? value.split(',') : '';
                                        return (<tr key={index}>
                                            {
                                                r.length>0 && r.map(td=>{
                                                    const tmp = td.split('-')
                                                    return <td key={tmp[1]}>{tmp[1]}</td>
                                                })
                                            }
                                        </tr>)
                                    })
                                }
                            </tbody>
                            </table>
                        }
                        if(text && text.length > 0 && text.length == 1){
                            return <Tag  icon={<i className={prefixIcon} aria-hidden="true"></i>} color={colors[2]}>
                            {text[0]}
                        </Tag>}else{
                        return (<>
                            {text && text.length > 0 ?
                                <Space wrap>
                                    <Popover content={content} title={header} trigger="hover">
                                        <Button>View {text.length}</Button>
                                    </Popover>

                                </Space> : config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>}
                        </>
                        )}
                    }
                }
                if (type === 'DROPDOWN' || (type === 'TYPE' && !columnKey.startsWith("$$SELF$$") && !collection)) {
                    let color = config && config.columnDropdownConfig && config.columnDropdownConfig.color ? config.columnDropdownConfig.color : 'volcano'
                    let icon = config && config.columnDropdownConfig && config.columnDropdownConfig.beforeIcon ? config.columnDropdownConfig.beforeIcon : null
                    const prefixIcon = `fa ${icon} mr-1`

                    renderCell = (text) => {
                        const contextual_text_color = config && config.contextual_text_color;
                        let textToRender = contextual_text_color && contextual_text_color.hasOwnProperty(text + '_text') ? contextual_text_color[text + '_text'] : text;
                        const iconClass = contextual_text_color && contextual_text_color.hasOwnProperty(text + '_icon') && contextual_text_color[text + '_icon'].cssClass ? contextual_text_color[text + '_icon'].cssClass : null;
                        const onlyIcon = contextual_text_color && contextual_text_color.hasOwnProperty(text + '_onlyIcon') ? contextual_text_color[text + '_onlyIcon'] : null;
                        const colorFromConfig = contextual_text_color && contextual_text_color[text] && contextual_text_color[text].code
                        let colorConfigured = colorFromConfig;
                        if (colorFromConfig === 'muted') {
                            colorConfigured = '#434343'
                        }
                        if (colorConfigured === 'info') {
                            colorConfigured = '#17a2b8'
                        }
                        if (colorConfigured === 'primary') {
                            colorConfigured = '#325CAA'
                        }
                        if (colorConfigured === 'danger') {
                            colorConfigured = '#dc3545'
                        }

                        return (
                            text ? (<Tag icon={<i className={prefixIcon} aria-hidden="true"></i>} color={colorConfigured ? colorConfigured : color}>
                                {iconClass && <i className={`fa ${iconClass} dropdownClassTablev2`} aria-hidden="true"></i>}  {!onlyIcon ? textToRender ? textToRender.toUpperCase() : text.toUpperCase() : null}
                            </Tag>) : config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>)
                    }
                }
            } else  if (type === 'MULTISELECT'){
                let colors = ['magenta',
                    'orange',
                    'gold',
                    'lime',
                    'green',
                    'cyan',
                    'blue',
                    'geekblue',
                    'purple', 'lime',
                    'green',
                    'cyan',
                    'blue',
                    'geekblue','lime',
                    'green',
                    'cyan',
                    'blue',
                    'geekblue','lime',
                    'green',
                    'cyan',
                    'blue',
                    'geekblue','lime',
                    'green',
                    'cyan',
                    'blue',
                    'geekblue']
                    renderCell = (text) => {
                        const content = text && text.length > 0 && text.map((value, index) => {
                            return (<div key={index}><Tag key={index} color={colors[index]} style={{marginBottom:5}}>
                                {value}
                            </Tag></div>)
                        })
                        return (<>
                            {text && text.length > 0 ?
                                <Space wrap>
                                    <Popover content={content} title={header} trigger="hover">
                                        <Button>View {text.length}</Button>
                                    </Popover>

                                </Space> : config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>}
                        </>
                        )
                    }
             }
            else if (type === 'FILE') {
                renderCell = (text) => text ? <a href="#" onClick={() => {
                    if (typeof text === "string" && text.startsWith("protected/")) {
                        client().get(`${config2.playerApi}/${text}`).then(res => {
                            window.open(res.data);
                        }).catch(e => {
                            console.log(e)
                        })
                    } else {

                        window.open((text && !text.startsWith('http')) ? (config2.assetsBasePath + text) : text)
                    }
                }} >
                    <Tooltip title={'Download'} placement="rightTop">
                        <FileProtectOutlined style={{ fontSize: 30 }} />
                    </Tooltip>
                </a> : config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>
            }
            else if (type === 'USER') {

            }
            else if (type === 'DATE' || type === 'DATETIME' || type === 'TIME') {

                renderCell = (text, item, index) => text ? <CellTypes.DATE text={text} workflow={workflow}
                    columnKey={column.field.classProperty}
                    column={column}
                    rows={rows}
                    config={config}
                    actions={inlineActions}
                    dateformat={dateformat}
                    rowIndex={index} /> : config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>
            }
            else if (type === 'URL') {

            }
            else if (type === 'LOCATION') {
                const showInModal = config.showInModal
                renderCell = (text) => text ? showInModal ? <div style={{ cursor: 'pointer' }} onClick={() => { this.toggleModal(text, config) }}>
                    <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${text}&zoom=12&size=80x80&maptype=roadmap&key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU`} />

                </div> : <a href={`https://www.google.com/maps/search/${text}/@${text},18z`} target="_blank" >
                    <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${text}&zoom=12&size=80x80&maptype=roadmap&key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU`} />
                </a> : config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>

                // renderCell = (text) => text ? <a href={`https://www.google.com/maps/search/${text}/@${text},18z`} target="_blank" >
                //     <i className="fa fa-external-link" area-hidden="true"></i>
                //     {"  Map"}
                // </a> : <span>Not Available</span>

            }
            else if (type === 'RATING') {

            } else if (type === 'IMAGE') {
                const showInModal = config.showInModal
                const columnImageConfig = config.columnImageConfig;

                renderCell = (t) => {
                    let text = t;
                    let thumbnail_text = text;       
                    if(thumbnail_text && thumbnail_text.endsWith('.jpg') && thmbnailFormatSelection && thmbnailFormatSelection.includes('jpg') ){
                   
                        thumbnail_text = thumbnail_text.replace('.jpg','-thumbnail.jpg')
                    }
                    else  if(thumbnail_text && thumbnail_text.endsWith('.png') && thmbnailFormatSelection && thmbnailFormatSelection.includes('png')){
                   
                        thumbnail_text = thumbnail_text.replace('.png','-thumbnail.png')
                    } else  if(thumbnail_text && thumbnail_text.endsWith('.jpeg') && thmbnailFormatSelection &&  thmbnailFormatSelection.includes('jpeg')){
                 
                        thumbnail_text = thumbnail_text.replace('.jpeg','-thumbnail.jpeg')
                    }
                    thumbnail_text  =  showThumnailImage ? thumbnail_text : text
                    
                    if(text && text.startsWith('file:///data/user') && companyDetails && companyDetails.data && companyDetails.data.name) {
                        const split = text.split("/");
                        const imageName = split[split.length - 1];
                        const companyName = companyDetails.data.name.toLowerCase();
                        text = `${companyName}/${imageName}`
                        thumbnail_text = `${companyName}/${imageName}`;
                    }
                    
                    if (columnImageConfig ) {
                        const height = columnImageConfig.height;
                        const width = columnImageConfig.width;
                        const borderRadius = columnImageConfig.borderRadius;
                        return text ? showInModal ? <div style={{ cursor: 'pointer' }}
                            onClick={() => { this.toggleImageModal(`${config2.assetsBasePath}${text}`, config) }}>
                            <img src={`${config2.assetsBasePath}${thumbnail_text}`} height={height ? parseInt(height) : 80} width={width ? parseInt(width) : 80} alt="TableImage" />
                        </div> : <a href={(text && !text.startsWith('http')) ? (config2.assetsBasePath + text) : text} target="_blank" >
                            <img src={`${config2.assetsBasePath}${thumbnail_text}`} height={height ? parseInt(height) : 80} width={width ? parseInt(width) : 80}
                                style={borderRadius ? { borderRadius: parseInt(borderRadius) } : {}} alt="TableImage" />
                        </a> : config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>
                    } else {
                        return (text ? showInModal ? <div style={{ cursor: 'pointer' }}
                            onClick={() => { this.toggleImageModal(`${config2.assetsBasePath}${text}`, config) }}>
                            <img src={`${config2.assetsBasePath}${thumbnail_text}`} height={80} width={80} alt="TableImage" />
                        </div> : <a href={(text && !text.startsWith('http')) ? (config2.assetsBasePath + text) : text} target="_blank" >
                            <img src={`${config2.assetsBasePath}${thumbnail_text}`} height={80} width={80} alt="TableImage" />
                        </a> : config.nullData ? <span>{config.nullData}</span> : <span>Not Available</span>)
                    }



                }

            } else if (type === 'BIT') {
                let color = config && config.columnDropdownConfig && config.columnDropdownConfig.color ? config.columnDropdownConfig.color : 'volcano'
                let icon = config && config.columnDropdownConfig && config.columnDropdownConfig.beforeIcon ? config.columnDropdownConfig.beforeIcon : null
                const prefixIcon = `fa ${icon} mr-1`
                renderCell = (text) => {
                    return (
                        text ? (<div className='bitClassNameTrue' >
                            <CheckCircleFilled style={{ color: 'green' }} />
                        </div>) : <div className='bitClassNameFalse'>
                            <CloseCircleFilled style={{ color: 'red' }} color='red' />
                        </div>)
                }
            }

            // if (type === 'LOCATION') {
            //     renderCell = (text) => text ?   <GoogleMap
            //     defaultZoom={8}
            //     defaultCenter={{ lat: -34.397, lng: 150.644 }}
            //   >
            //     {props.isMarkerShown && <MArke position={{ lat: -34.397, lng: 150.644 }} />}
            //   </GoogleMap>
            //  : <span>Not Available</span>

            // }

            let columnModelSelector = column.config.modelSelector
            columnModelSelector && columnModelSelector.replace('{{', '');
            columnModelSelector && columnModelSelector.replace('}}', '');
            let fixedColumnAlignment = config.fixedColumnAlignment ? config.fixedColumnAlignment : null;

            function capitalizeFirstLetter(str) {
                return str && str.charAt(0).toUpperCase() + str.slice(1);
            }


            const columnHeader = column.config.header;
            const capitalizedText = capitalizeFirstLetter(columnHeader);
            let columnWidth = column.config && column.config.styles ? column.config.styles.columnWidth : 150;
            columnWidth = parseInt(columnWidth)

            const datIndexName = column.field.type === 'TYPE' ? column.field.classProperty.startsWith("$$SELF$$") ? column.field.classProperty : column.field.classProperty + '_type' : column.field.classProperty

            const filterDataOption = [];

            const possibleValues = column.field.properties && column.field.properties.possibleValues;
            const contextual_text_color = column.config && column.config.contextual_text_color

            const obj = possibleValues && possibleValues.map((val, index) => {
                let objsmall = { text: null, value: null };
                objsmall.text = contextual_text_color && contextual_text_color[`${val}_text`] ? contextual_text_color[`${val}_text`] : val;
                objsmall.value = val;
                filterDataOption.push(objsmall);
            })

            const columnType = column.field.type


            if (filterDataOption && filterDataOption.length > 0) {
                columnsAntd[columnIndex] = {
                    title: capitalizedText,
                    dataIndex: datIndexName,
                    modelSelector: column.config.modelSelector,
                    key: column.field.name,
                    filters: filterDataOption,
                    render: renderCell,
                    fixed: fixedColumnAlignment,
                    width: columnWidth ? columnWidth : null,
                    ellipsis: true,
                    onFilter: (value, record) => record[datIndexName] && record[datIndexName].includes(value),
                    ...column
                }
            } else if (columnType === 'SMALLTEXT') {
                columnsAntd[columnIndex] = {
                    title: capitalizedText,
                    dataIndex: datIndexName,
                    modelSelector: column.config.modelSelector,
                    key: column.field.name,
                    render: renderCell,
                    fixed: fixedColumnAlignment,
                    width: columnWidth ? columnWidth : null,
                    ellipsis: true,
                    sorter: (a, b) => a[datIndexName].length - b[datIndexName].length,
                    ...column
                }
            } else {
                columnsAntd[columnIndex] = {
                    title: capitalizedText,
                    dataIndex: datIndexName,
                    modelSelector: column.config.modelSelector,
                    key: column.field.name,
                    render: renderCell,
                    fixed: fixedColumnAlignment,
                    width: columnWidth ? columnWidth : null,
                    ellipsis: true,
                    ...column
                }
            }


        })


        let dataNew = rows['_rows'];


        dataNew && dataNew.map((row, rowIndex) => {
            dataNew[rowIndex] = {
                key: rowIndex,
                ...row
            }
        })

        columnsAntd && columnsAntd.map((column, columnIndex) => {
            const columnKey = column.field.classProperty;
            const fieldType = column.field.type;
            const collection = column.field.collection;
            const config = column && column.config;
            const caluclateField = config && config.styles && config.styles.caluclateField ? config.styles.caluclateField : null
     

            if (fieldType === 'TYPE' && columnKey.startsWith("$$SELF$$")) {

                dataNew && dataNew.map((row, rowIndex) => {
                    let typeModeldata = dataNew[rowIndex];
                    let text = ModelPropertiesParserCal(
                        column.config.modelSelector,
                        typeModeldata,
                        caluclateField ? 'dynamic' : ''
                    );
                    let obj = {}
                    obj[columnKey] = text;
                    dataNew[rowIndex] = {
                        ...obj,
                        ...row
                    }
                })
            } else if (fieldType === 'TYPE' && !collection) {

                let newDatKey = column.field.classProperty + '_type'

                dataNew && dataNew.map((row, rowIndex) => {
                    let text = ModelPropertiesParser(
                        column.config.modelSelector,
                        rows.getObjectAt(rowIndex)[columnKey]
                    );
                    let obj = {}
                    const analysedText = analyseEvaluatedText(text);
                    obj[newDatKey] = text;
                    dataNew[rowIndex] = {
                        ...obj,
                        ...row
                    }
                })
            } else if (fieldType === 'TYPE' && collection) {

                let newDatKey = column.field.classProperty + '_type'


                dataNew && dataNew.map((row, rowIndex) => {
                    const models = rows.getObjectAt(rowIndex)[columnKey];
                    let data = []
                    models && models.map((model, index) => {
                        let text = ModelPropertiesParser(
                            column.config.modelSelector,
                            model
                        );
                        data.push(text)
                    });
                    let obj = {}
                    obj[newDatKey] = data;
                    dataNew[rowIndex] = {
                        ...obj,
                        ...row
                    }
                })
            }

        })
        if (filterSystemGenratedActions.length) {

            const actionIndex = columnsAntd.find(col => col.key == 'action');
            const typeModelId = this.props.typeModelId;
            const containerId = this.props.containerIndex;
            const columnIndex = this.props.columnIndex;
            if (!actionIndex) {
                columnsAntd.push({
                    title: selectedTemplate === 'Template3' ? '' : 'Actions',
                    key: 'action',
                    field: {},
                    config: {},
                    fixed: !tableActionPosition ? 'right' : tableActionPosition,
                    className: 'table-actions',
                    width: selectedTemplate === 'Template3' ? 1 : actionColumnWidth ? actionColumnWidth : 150,
                    render: (text, item, index) => {
                        return <ActionCell
                            classNameAction={'table-action'}
                            actions={inlineActions}
                            rows={rows}
                            workflow={workflow}
                            rowHeight={rowHeight}
                            colWidth={selectedTemplate === 'Template3' ? 1 : actionColumnWidth}
                            selectedTemplate={selectedTemplate}
                            menuTypeInlineAction={menuTypeInlineAction}
                            rowIndex={index}
                            keyValue={text && text.key}
                            columnKey='id'
                            columnIndex={columnIndex}
                            containerId={containerId}
                            typeModelId={typeModelId}
                        />
                    }
                })
            }
        }

        let columnsNew = columnsAntd;

        const maxWidthPerCell = 600;
        const tableV2Height = 500;
        const rowKey = "id";

        // This helper function helps to calculate the width for each column
        // based on all table cells - column cell and source cell
        const dataTable = calculateColumnsWidth(columnsNew, dataNew, maxWidthPerCell);

        if (selectedTemplate == 'Template2' || selectedTemplate == 'TEMPLATE_TWO' || selectedTemplate == 'Template3') {

            return (
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        this.setState({ dimensions: contentRect.bounds })
                    }}
                >
                    {({ measureRef }) =>
                        <div ref={measureRef} className='mb-2 table'>

                            {(allowDownloadAsPdf && operation == 'READALL') || isReadOneTable ? <div className='mb-3' style={{ marginTop: -30, float: 'right', width: 35 }}>
                                <FileDownload rows={allDataDownload && allData.length > 0 ? allRows : rows} columns={columns} name={fileName} dateformat={dateformat} duplicateRowsForOneToMany={workflow.currentItem.layoutProperties.duplicateRowsInOneToMany} /></div> : null}
                            {/* <DynamicColumnsTable
                            columns={dataTable.columns}
                            height={tableHeight}
                            rowKey={rowKey}
                            source={dataTable.source}
                            width={dataTable.tableWidth}
                            actions={inlineActions}
                            rows={rows}
                            columnKey='id'
                            workflow={workflow}
                            rowHeight={rowHeight}
                            colWidth={actionColumnWidth}
                            menuTypeInlineAction={menuTypeInlineAction}
                        /> */}

                            {selectedTemplate === 'Template3' && <Search
                                filterTable={filterTable}
                                dataNew={dataNew}
                                filterSearchData={this.filterSearchData}
                            />}

                            <Table2 size={size ? size : 'large'}
                                pagination={enablePagination ? enablePagination ? { pageSize: pagination, position: [topPostion ? topPostion : 'bottomRight'] } : {} : false}
                                scroll={scroll ? { y: scroll, scrollToFirstRowOnChange: true, x: 'max-content' } : { scrollToFirstRowOnChange: true, x: 'max-content' }}
                                bordered={bordered} footer={footer ? () => footer ? footer : null : false}
                                columns={columnsNew} dataSource={filterTable == null ? dataNew : filterTable} onChange={this.onChange}
                                sticky={{ offsetHeader: 2}}
                                 textAlign='center' verticalAlign='middle'
                                
                            />
                            <LocationModal toggleModal={this.toggleModal} showModal={this.state.locationModalOpen}
                                location={this.state.locationValue} config={this.state.config} />
                            <ImageModal toggleModal={this.toggleImageModal} showModal={this.state.imageModalOpen}
                                valueToRender={this.state.imageValue} config={this.state.config} />
                        </div>
                    }
                </Measure>
            )
        } else {

            return (
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        this.setState({ dimensions: contentRect.bounds })
                    }}
                >
                    {({ measureRef }) =>
                        <div ref={measureRef} className='mb-2'>
                            {(allowDownloadAsPdf && operation == 'READALL') || isReadOneTable ? <div className='mb-3' style={{ marginTop: -30, float: 'right', width: 35 }}>
                                <FileDownload rows={allDataDownload && allData.length > 0 ? allRows : rows} columns={columns} name={fileName} dateformat={dateformat} duplicateRowsForOneToMany={workflow.currentItem.layoutProperties.duplicateRowsInOneToMany} /></div> : null}
                            <Table
                                rowHeight={rowHeight}
                                rowsCount={rows.getSize()}
                                headerHeight={50}
                                width={tableWidth}
                                className='neene-table'
                                maxHeight={tableHeight}>
                                {
                                    canActOnMultipleItems || this.props.readOnlyTableWithSelection ? <Column
                                        key={`item_selector_column`}
                                        columnKey='id'
                                        header={this.props.readOnlyTableWithSelection ?
                                            <InputField onChange={this.toggleSelectAll.bind(this)}
                                                style={{ position: 'relative', width: 35, height: 35, marginLeft: '4px', marginTop: '8px' }}
                                                type="checkbox" /> : ''}
                                        cell={
                                            <RowSelectorCell rows={rows}
                                                readOnlyTableWithSelection={this.props.readOnlyTableWithSelection}
                                                classProperty={this.props.name}
                                                changeVal={this.props.changeVal}
                                                formValues={this.props.formValues}
                                                hasModifiedClassProperty={this.props.hasModifiedClassProperty}
                                                getNewContext={this.props.getNewContext}
                                                selectAll={this.state.selectAll}
                                            />
                                        }
                                        width={50}
                                    /> : null
                                }
                                {
                                    columns.map((column, index) => {
                                        const { field: { type }, config } = column;
                                        const columnWidthCustom = config && config.styles && config.styles.columnWidth ? parseInt(config.styles.columnWidth) : null
                                        const hideColumn = config && config.styles && config.styles.hideColumn ? config.styles.hideColumn : null
                                        let CellByType = CellTypes.SIMPLETEXTCELL;
                                        const WidthByType = WIDTHS[type];
                                        const TypeWidth = columnWidthCustom ? { width: columnWidthCustom, flexGrow: 0 } : WidthByType ? WidthByType : { width: 100, flexGrow: 0 };
                                        if (type === 'TYPE') {
                                            CellByType = CellTypes.TYPE;
                                        }
                                        if (type === 'FILE') {
                                            CellByType = CellTypes.FILE;
                                        }
                                        if (type === 'USER') {
                                            CellByType = CellTypes.USER;
                                        }
                                        if (type === 'DATE' || type === 'DATETIME' || type === 'TIME') {
                                            CellByType = CellTypes.DATE;
                                        }
                                        if (type === 'URL') {
                                            CellByType = CellTypes.URL;
                                        }
                                        if (type === 'LOCATION') {
                                            CellByType = CellTypes.LOCATION;
                                        }
                                        if (type === 'RATING') {
                                            CellByType = CellTypes.RATING;
                                        }
                                        let flexGrow = TypeWidth.flexGrow;
                                        if (hasExtraSpace) {
                                            flexGrow = flexGrow === 0 ? 1 : flexGrow + 1;
                                        }
                                        if (hideColumn) return null;
                                        return <Column
                                            key={`data_column_${index}`}
                                            cellClassName={`cell_class_${index}`}
                                            className={`column_class_${index}`}
                                            header={<Cell
                                                onClick={() => { this.sortByColumn(column.field.classProperty) }}
                                                className='neene-table-header'>
                                                {column.config.header}
                                                {
                                                    column.config.sortable && (
                                                        <span className="table-sort-icon">
                                                            <i className={`fa ${column.field.classProperty === sorting.classProperty ? sorting.order === 'ASCENDING' ? 'fa-sort-amount-desc active' : 'fa-sort-amount-asc active' : 'fa-sort'}`}></i>
                                                        </span>
                                                    )
                                                }
                                            </Cell>
                                            }
                                            columnKey={column.field.classProperty}
                                            cell={<CellByType workflow={workflow} column={column} rows={rows} config={config} actions={inlineActions} dateformat={dateformat} />}
                                            width={TypeWidth.width}
                                            flexGrow={flexGrow}
                                        />
                                    })
                                }
                                {
                                    filterSystemGenratedActions.length ? <Column
                                        key={`action_column`}
                                        columnKey='id'
                                        header={<Cell className='neene-table-header'>Actions</Cell>}
                                        cell={<ActionCell
                                            actions={inlineActions}
                                            rows={rows}
                                            workflow={workflow}
                                            rowHeight={rowHeight}
                                            colWidth={actionColumnWidth}
                                            menuTypeInlineAction={menuTypeInlineAction}
                                        />}
                                        width={actionColumnWidth}
                                        fixedRight={true}
                                        flexGrow={1}
                                    /> : null
                                }
                            </Table>
                        </div>
                    }
                </Measure>
            )
        }
    }
}

const LocationModal = (props) => {
    const valueToRender = props.location;
    const iframeStyle = { border: 'none' };
    const size = props.config && props.config.modalSize ? props.config.modalSize.value : 'lg';
    const hideHeader = props.config && props.config.hideModalHeader ? props.config.hideModalHeader : true;
    return <Modal className='modal-class-common' size={`${size}`} isOpen={props.showModal} toggle={() => props.toggleModal()} >
        {!hideHeader && <ModalHeader toggle={props.toggleModal}>
            {props.config.header}
        </ModalHeader>
        }
        <ModalBody className='p-0'>
            <iframe
                width="100%" height="100%" style={iframeStyle}
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&q=${valueToRender}&center=${valueToRender}`}
                allowFullScreen>
            </iframe>
        </ModalBody>
    </Modal>
}

const ImageModal = (props) => {
    const valueToRender = props.valueToRender;
    const size = props.config && props.config.modalSize ? props.config.modalSize.value : 'lg';
    const hideHeader = props.config && props.config.hideModalHeader ? props.config.hideModalHeader : true;
    return <Modal className='modal-class-common' size={`${size}`} isOpen={props.showModal} toggle={() => props.toggleModal()} >
        {!hideHeader && <ModalHeader toggle={props.toggleModal}>
            {props.config.header}
        </ModalHeader>
        }
        <ModalBody className='d-flex justify-content-center p-0'>
            <img width="100%" height="100%" src={`${valueToRender}`} />
        </ModalBody>
    </Modal>
}

VirtualizedTable.defaultProps = {
    rows: null,
    columns: [],
    //typeModelName: null
}

VirtualizedTable.propTypes = {
    rows: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    //typeModelName: PropTypes.string
};

export default VirtualizedTable;