import React, { Component } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import * as Elements from '../elements/index';
import { operation as workflowOperation } from '../../dto/workflow';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import { startsWith } from 'lodash';

class LayoutItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: true,
      showButton: false,
      validations: [],
      itemId: ''
    };
  }

  componentDidMount() {
    const {
      workflow: { sessionId, sessionParams, currentItem, currentItem: { operation } },
      layoutItem,
      resolveRules,
      formValues,
      classProperty,
      workflowData,
      inlineFormKey,
      change,
      companyDetails,
      additionalModelData
    } = this.props;
    if (layoutItem && layoutItem.itemProperties && typeof layoutItem.itemProperties.replaceClassProperty === 'string' && operation !== 'READONE') {
      const fields = currentItem.typeModel.fields;
      const checkpropery = layoutItem.itemProperties.replaceClassProperty.split('.');
      fields.map((field) => {
        if (checkpropery[0] === field.classProperty) {

          let customClassName = field.customTypeClass;
          let customerName = companyDetails.data && companyDetails.data.name.toLowerCase();
          let modelVersion = companyDetails.data && 'v' + companyDetails.data.modelsVersion

          const iModelVerision = companyDetails.data && companyDetails.data.modelsVersion
          let className = customClassName;
          for (let i = 0; i <= iModelVerision; i++) {
            if (className.indexOf('v' + i + '.') != -1) {
              className = className.replace('v' + i + '.', modelVersion + '.').replace(customerName.capitalize() + '_v' + i, customerName.capitalize() + '_' + modelVersion);
              try {
                change(`${checkpropery[0]}.class`, className)
              } catch (eror) {
                console.log('Error while changing the value');
              }
              break;
            }
          }


        }
      })

    }
    const itemProperties = layoutItem ? layoutItem.itemProperties : null;
    if (itemProperties && itemProperties.displayConfig && itemProperties.displayConfig.displayWhen) {
      if (itemProperties.displayConfig.displayWhen.value === 'basedOnRule') {
        if (
          itemProperties.connector &&
          itemProperties.connector.rules &&
          itemProperties.connector.rules.length
        ) {

          const rules = itemProperties.connector.rules;
          let cacheId = sessionId;
          rules.forEach((x, i) => {
            if (x.key) {
              cacheId += "_" + x.key.typeModelName;
              cacheId += "_" + x.key.property;
              cacheId += "_" + x.key.staticValue;
            }
            cacheId += "_" + x.operator;
            if (x.value) {
              cacheId += "_" + x.value.staticValue;
              cacheId += "_" + x.value.typeModelName;
              cacheId += "_" + x.value.property;
            }
          });
          let chkParamRule = false
          rules.forEach((x, i) => {
            if (x && x.sessionParamCode == "HasRequestParam") {
              let staticValue = x.value.staticValue
              chkParamRule = true
              staticValue = staticValue.split('=')
              const sessionParamKey = Object.keys(sessionParams)
              sessionParamKey.forEach((key) => {
                if (key == staticValue[0] && sessionParams[key] == staticValue[1]) {
                  this.setState({ hide: false });
                  this.props.setDisplay(true)

                }
              })
            }
          })
          if (!window.rulesCache) window.rulesCache = [];
          const self = this;
          if (window.rulesCache[cacheId] && 1 == 2) {
            self.setState({ hide: !window.rulesCache[cacheId].data });
          } else {
            if (!chkParamRule) {
              if (itemProperties.displayConfig.checkRuleLocally) {
                let r = true;
                rules.forEach((x, i) => {
                  const property = x.key.property;
                  const staticValue = x.key.staticValue;
                  let v = workflowData && ModelPropertiesParser(`{{${property}}}`, workflowData)
                  if(staticValue){
                    v = staticValue.indexOf('{{') == -1 ? staticValue : ModelPropertiesParser(staticValue, workflowData);
                  }
                  
                  if (x.value.staticValue) {
                    if (v == x.value.staticValue) {
                      
                    } else {
                      if(r){
                        r = false;
                      }
                    }

                  } else {
                    let rightModelName = x.value.typeModelName;
                    rightModelName = rightModelName.replace(' ','_');
                    const rightObj = rightModelName === currentItem.typeModel.name ? workflowData : additionalModelData && additionalModelData[rightModelName];
                    const rightObjValue = rightObj && rightObj[x.value.property];
                    if (rightObjValue == v) {
                      
                    } else {
                      if(r){
                        r = false;
                      }
                    }
                  }

                })
                if(r){
                  self.setState({ hide: false });
                  this.props.setDisplay(true)
                }

              } else {
                resolveRules({
                  rules: itemProperties.connector.rules,
                  sessionId,
                }).then(rules => {
                  if (rules.data) {
                    window.rulesCache[cacheId] = rules.data;
                    self.setState({ hide: !rules.data.data });
                  }
                });
              }

            }
          }
        } else {
          this.setState({ hide: true });
          this.props.setDisplay(false)
        }
      } else if (
        itemProperties.displayConfig.displayWhen.value === 'onButtonPress'
      ) {
        this.setState({ showButton: true, hide: true });
        this.props.setDisplay(false)
      } else if (
        itemProperties.displayConfig.displayWhen.value === 'onDataChange'
      ) {
        const field = itemProperties.displayConfig.dataField;
        let fieldValue = '';
        if (field && field.indexOf('.') == -1) {
          fieldValue = formValues && formValues[`${field}`];
          if (inlineFormKey && inlineFormKey.startsWith('workflow-card')) {
            fieldValue = workflowData && ModelPropertiesParser(`{{${field}}}`, workflowData)
          }
        } else {
          fieldValue = formValues && ModelPropertiesParser(`{{${field}}}`, formValues)
          if (inlineFormKey && inlineFormKey.startsWith('workflow-card')) {
            fieldValue = workflowData && ModelPropertiesParser(`{{${field}}}`, workflowData)
          }
        }
        let values = itemProperties.displayConfig.updatedData;
        values = (values && values.indexOf(',') != -1) ? values.split(",").map((item) => item.trim()) : values;
        if (typeof values == 'string') {
          if (values.indexOf('{{notnull}}') !== -1) {
            if (typeof fieldValue !== 'undefined' && fieldValue !== null) {
              this.setState({ hide: false });
              this.props.setDisplay(true)
            } else {
              this.setState({ hide: true });
              this.props.setDisplay(false)
            }
          } else {
            if (values !== fieldValue) {
              this.setState({ hide: true });
              this.props.setDisplay(false)
            } else {
              this.setState({ hide: false });
              this.props.setDisplay(true)
            }
          }
        } else {
          const found = values && values.find(element => element == fieldValue);
          if (found) {
            this.setState({ hide: false });
            this.props.setDisplay(true)
          } else {
            this.setState({ hide: true });
            this.props.setDisplay(false)
          }
        }

      } else if (
        itemProperties.displayConfig.displayWhen.value === 'always'
      ) {
        this.setState({ hide: false });
      }
    } else {
      this.setState({ hide: false });
      this.props.setDisplay(true)
    }
    const validations = this.props.getValidations(layoutItem);
    if (validations)
      this.setState({ validations, itemId: layoutItem ? layoutItem.id : '' });


    if(layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.customModelName && layoutItem.itemProperties.customClassName) {

      const customClassName = layoutItem.itemProperties.customClassName;
      let parentClassName = layoutItem.itemProperties.parentClass;
      parentClassName = parentClassName ? parentClassName : '';
      let className = customClassName;
      let modelVersion = companyDetails.data && 'v' + companyDetails.data.modelsVersion;
      let customerName = companyDetails.data.name.toLowerCase();
      const iModelVerision = companyDetails.data && companyDetails.data.modelsVersion;

      for (let i = 0; i <= iModelVerision; i++) {
        if (className.indexOf('v' + i + '.') != -1) {
          className = className.replace('v' + i + '.', modelVersion + '.').replace(customerName.capitalize() + '_v' + i, customerName.capitalize() + '_' + modelVersion);
          parentClassName = parentClassName.replaceAll('v' + i + '.', modelVersion + '.').replaceAll(customerName.capitalize() + '_v' + i, customerName.capitalize() + '_' + modelVersion);
          let parentClassArr = parentClassName.split(',');
          try {
            let checkpropery = layoutItem.itemProperties.replaceClassProperty.split('.');
            checkpropery.splice(checkpropery.length - 1, 1);
            const parentClass = checkpropery.join(".");
            change(`${parentClass}.class`, className);
            let key = parentClassArr.length-1;
            while(key >= 0){
              if(parentClassArr[key] == className){
                const newParentClass = parentClass.split('.')
                newParentClass.splice(newParentClass.length - 1, 1);
                const tmpParent = newParentClass.join(".");
                if(!ModelPropertiesParser(`{{${tmpParent}.class}}`, formValues)){
                  change(`${tmpParent}.class`, parentClassArr[key-1]);
                }
              }
              key--;
            }
          } catch (eror) {
            console.log('Error while changing the value');
          }
          break;
        }
      }

      
    }
  }

  onBtnPress() {
    this.setState({ hide: false });
    this.props.setDisplay(true)
  }

  componentWillReceiveProps(nextProps) {
    const {
      workflow: { sessionId, currentItem: { operation } },
      layoutItem,
      resolveRules,
      formValues,
      classProperty,
      timerIndex,
      uiActions,
      workflowData,
      inlineFormKey
    } = nextProps;
    const itemProperties = layoutItem ? layoutItem.itemProperties : null;
    const timerIndexOld = this.props.timerIndex;
    if (itemProperties && itemProperties.displayConfig && itemProperties.displayConfig.displayWhen) {
      if (itemProperties.displayConfig.displayWhen.value === 'basedOnRule') {
        const resolveRulesFlag = itemProperties.timerConfig
          ? timerIndexOld != timerIndex
            ? true
            : false
          : true;
        if (resolveRulesFlag) {
          if (
            itemProperties.connector &&
            itemProperties.connector.rules &&
            itemProperties.connector.rules.length
          ) {
            const rules = itemProperties.connector.rules;
            let cacheId = sessionId;
            rules.forEach((x, i) => {
              if (x.key) {
                cacheId += "_" + x.key.typeModelName;
                cacheId += "_" + x.key.property;
                cacheId += "_" + x.key.staticValue;
              }
              cacheId += "_" + x.operator;
              if (x.value) {
                cacheId += "_" + x.value.staticValue;
                cacheId += "_" + x.value.typeModelName;
                cacheId += "_" + x.value.property;
              }
            });
            if (!window.rulesCache) window.rulesCache = [];
            const self = this;
            if (window.rulesCache[cacheId] && 1 == 2) {
              self.setState({ hide: !window.rulesCache[cacheId].data });
            } else {
              if (this.props.itemIndex !== nextProps.itemIndex) {
                resolveRules({
                  rules: itemProperties.connector.rules,
                  sessionId,
                }).then(rules => {
                  if (rules.data) {
                    window.rulesCache[cacheId] = rules.data;
                    self.setState({ hide: !rules.data.data });
                  }
                });
              }
            }
          } else {
            this.setState({ hide: true });
            this.props.setDisplay(false)
          }
        }
      } else if (
        itemProperties.displayConfig.displayWhen.value === 'onDataChange'
      ) {
        const field = itemProperties.displayConfig.dataField;
        let fieldValue = ''
        if (field && field.indexOf('.') == -1) {
          fieldValue = formValues && formValues[`${field}`];
          if (inlineFormKey && inlineFormKey.startsWith('workflow-card')) {
            fieldValue = workflowData && ModelPropertiesParser(`{{${field}}}`, workflowData)
          }
        } else {
          fieldValue = formValues && ModelPropertiesParser(`{{${field}}}`, formValues)
          if (inlineFormKey && inlineFormKey.startsWith('workflow-card')) {
            fieldValue = workflowData && ModelPropertiesParser(`{{${field}}}`, workflowData)
          }
        }

        if (fieldValue === true) {
          fieldValue = JSON.stringify(fieldValue);
        }
        let values = itemProperties.displayConfig.updatedData;
        values = (values && values.indexOf(',') != -1) ? values.split(",").map((item) => item.trim()) : values;
        if (typeof values == 'string') {
          if (values.indexOf('{{notnull}}') !== -1) {
            if (typeof fieldValue !== 'undefined' && fieldValue !== null) {
              this.setState({ hide: false });
              this.props.setDisplay(true)
            } else {
              this.setState({ hide: true });
              this.props.setDisplay(false)
            }
          }
          else if (fieldValue && typeof fieldValue == 'string' && values == fieldValue) {
            this.setState({ hide: false });
            this.props.setDisplay(true)
          } else if (fieldValue && typeof fieldValue != 'string' && fieldValue.length) {
            const found = fieldValue && fieldValue.find(element => element == values);
            if (found) {
              this.setState({ hide: false });
              this.props.setDisplay(true)
            }
          } else {
            this.setState({ hide: true });
            this.props.setDisplay(false)
          }
        } else {
          if (fieldValue && typeof fieldValue == 'string') {
            const found = values && values.find(element => element == fieldValue);
            if (found) {
              this.setState({ hide: false });
              this.props.setDisplay(true)
            } else {
              this.setState({ hide: true });
              this.props.setDisplay(false)
            }
          } else if (fieldValue && typeof fieldValue != 'string' && fieldValue.length) {
            var found = fieldValue.filter((item) => !values.includes(item));
            if (found) {
              this.setState({ hide: false });
              this.props.setDisplay(true)
            }
          } else {
            this.setState({ hide: true });
            this.props.setDisplay(false)
          }
        }
      }
    }

    if (this.props.itemIndex !== nextProps.itemIndex) {
      const validations = this.props.getValidations(layoutItem);
      if (validations)
        this.setState({ validations, itemId: layoutItem ? layoutItem.id : '' });
    }
  }

  render() {
    const { layoutItem, workflow: { currentItem }, type, companyDetails, change, customStyles } = this.props;
    const itemProperties = layoutItem ? layoutItem.itemProperties : null;
    const hideInWeb = itemProperties ? itemProperties.hideInWeb : null;
    const hideInMobileWebView = itemProperties ? itemProperties.hideInMobileWebView : null;
    if (hideInWeb) return null;
    if (hideInMobileWebView && window.innerWidth < 480) return null;
    let { classProperty, ...rest } = this.props;
    let higherZindex = false;
    if (!layoutItem) return null;
    const displayConfig = layoutItem.itemProperties.displayConfig;
    const disableBeforDataChange = displayConfig && displayConfig.disableBeforDataChange;

    const webElement = layoutItem.itemProperties.webElement;
    const viewClass = webElement && webElement.viewClass;
    let customStylesData = customStyles && customStyles.data && customStyles.data.data && customStyles.data.data.style;
    customStylesData = customStylesData ? JSON.parse(customStylesData) : {};
    const eleStyle = customStylesData[`${viewClass}`];
    let ResolvedComponent = Elements[type];
    if (ResolvedComponent === null || !ResolvedComponent) {
      ResolvedComponent = Elements.FALLBACK;
    }

    if (typeof layoutItem.itemProperties.replaceClassProperty === 'string' && layoutItem.itemProperties.replaceClassProperty != "") {
      classProperty = layoutItem.itemProperties.replaceClassProperty;
    }
    if (type === 'TYPE' || type === 'USER' || type === 'DROPDOWN') {
      higherZindex = true;
    }

    let handleAuthentication = () => { };
    if (type === 'AUTH') {
      handleAuthentication = () => {
        this.props.next();
        //this.props.fetchNavWorkflows && this.props.fetchNavWorkflows();
      };
    }
    const additionalClass = layoutItem.itemProperties.animationType
      ? 'wow ' + layoutItem.itemProperties.animationType.value
      : '';
    return (
      <div
        className={`layout-item-wrapper`}
        data-aos={
          layoutItem.itemProperties.animationType
            ? layoutItem.itemProperties.animationType.value
            : ''
        }
        data-aos-delay={
          layoutItem.itemProperties.animationDelay
            ? layoutItem.itemProperties.animationDelay.value
            : ''
        }
      >
        {(disableBeforDataChange || !this.state.hide) && (
          <ResolvedComponent
            {...rest}
            validations={this.state.validations}
            classProperty={classProperty}
            disableLayoutItem={this.state.hide}
            authenticationCallback={handleAuthentication}
            eleStyle={eleStyle}
          />
        )}
        {this.state.showButton && (
          <Button
            onClick={this.onBtnPress.bind(this)}
            color={displayConfig.buttonStyle}
          >
            <i
              className={`fa ${displayConfig.cssClass} mr-1`}
              aria-hidden='true'
            ></i>
            {displayConfig.buttonName}
          </Button>
        )}
      </div>
    );
  }
}

export default LayoutItem;
