import React, { Component } from 'react'
import {
	FormGroup, Label, FormFeedback, Button, Modal, ModalHeader, ModalBody,
	Collapse, Card, CardBody, CardHeader, Input
} from 'reactstrap';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import config from '../../config';
import JSONPath from 'jsonpath';
import CanvasDraw from "react-canvas-draw";
import ImageMapper from 'react-img-mapper';
import { getMatchedAction } from '../workflow/action/workflowActionsUtils';
import { triggerAction } from '../../actions/workflow-actions';

export default class StaticImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			image: null,
			imageData: null,
			currentScale: 1,
			currentPan: 0,
			imageUrl: null,
			mousePos: { x: 0, y: 0 },
			savedPos: null,
			imageMap: [],
			popover: null,
			toggleModel: false,
			toggler: 'accordian-0',
			lines: [],
			focusedPoint: ''
		}
		this.container = null;
		this.canvasA = null;
		this.toggle = this.toggle.bind(this);
		this.toggleEditModel = this.toggleEditModel.bind(this);
		//this.checkRule = this.checkRule.bind(this);
	}

	handleToggler = (id) => {
		if (this.state.toggler === id) {
			this.setState({ toggler: null });
		} else {
			this.setState({ toggler: id });
		}
	}

	setFieldValue = () => {
		const { workflowData, layoutItem,
			workflow: { currentItem: { typeModel: { customClassName, customerName } } } } = this.props;
		const { itemProperties: { staticImageConfig } } = layoutItem;
		const imageHotspotField = staticImageConfig && staticImageConfig.imageHotspotField;
		const imageHotspotFieldClass = staticImageConfig && staticImageConfig.imageHotspotFieldClass;
		let modelVersion = customClassName.replace('com.uttara.neene.proxyto.' + customerName + '_', '');
		modelVersion = modelVersion.substring(0, modelVersion.indexOf('.'));
		const iModelVerision = parseInt(modelVersion.substring(1));
		let className = imageHotspotFieldClass;
		for (let i = 0; i < iModelVerision; i++) {
			if (className.indexOf('v' + i + '.') != -1)
				className = className.replace('v' + i + '.', modelVersion + '.').replace(customerName.capitalize() + '_v' + i, customerName.capitalize() + '_' + modelVersion);
		}
		let savedLines = staticImageConfig && workflowData && staticImageConfig.imageHotspotField &&
			JSONPath.value(workflowData, `$.${staticImageConfig.imageHotspotField}`);

		let valueField = staticImageConfig && staticImageConfig.valueField;
		valueField = valueField.split(`${staticImageConfig.imageHotspotField}.`)
		let tmp = !savedLines ? [] : savedLines;
		let t = this.loadableCanvas.getSaveData();
		t = t && JSON.parse(t);

		const lines = t ? t.lines : [];
		lines.map((l, index) => {
			let obj = { class: className };
			const arr = l.points;
			const brushColor = l.brushColor.split('#');
			const f = tmp.find(t => t.id == brushColor[1]);
			if (!f) {
				obj[`${valueField[1]}`] = JSON.stringify(arr);
				tmp.push(obj)
			}

		})
		this.props.change(`${imageHotspotField}`, tmp)

	}

	toggle(area) {
		this.setState({ popover: area ? area : null })
	}

	componentDidMount() {
		const { workflow: { currentItem: { inlineAction } }} = this.props;
		const { staticImageConfig } = this.props.layoutItem.itemProperties;
		let imgUrl = staticImageConfig && staticImageConfig.url;
		const imageField = staticImageConfig && staticImageConfig.imageField;
		const { workflowData } = this.props;
		let mainAction = null;
		if (imageField && workflowData) {
			imgUrl = ModelPropertiesParser(`{{${imageField}}}`, workflowData);
			imgUrl = imgUrl.startsWith('http') ? imgUrl : config.assetsBasePath + imgUrl;
		}
		if (staticImageConfig.isTextualActionTriggerEnabled) {
			mainAction = getMatchedAction(
				inlineAction,
				staticImageConfig.textualActionTriggerCode
			);
		}
		let { imageUrl } = this.state;
		const textOne = staticImageConfig.textOne;
		const textTwo = staticImageConfig.textTwo;
		let valueField = staticImageConfig && staticImageConfig.valueField;
		valueField = valueField.replace(`${staticImageConfig.imageHotspotField}.`,'')
		let lines = staticImageConfig && workflowData && staticImageConfig.imageHotspotField &&
			JSONPath.value(workflowData, `$.${staticImageConfig.imageHotspotField}`);
		lines = !lines ? [] : lines;
		let areaMap = [];
		let savedData = { lines: [] };
		lines.map((l, index) => {
			let arr = ModelPropertiesParser(`{{${valueField}}}`, l);
			arr = arr ? JSON.parse(arr) : [];
			savedData.lines.push({ points: arr, brushColor: `#${l.id}`, brushRadius: 1 })
			let tmp = ''
			const size = arr.length > 10 ? Math.ceil(arr.length / 10) : arr.length;
			tmp = Array.from({ length: size < 10 ? size : 10 }, (v, i) =>
				arr.slice(i * size, i * size + size)
			);
			const t1 = textOne ? textOne.replace(`${staticImageConfig.imageHotspotField}.`,'') : null;
			const t2 = textTwo ? textTwo.replace(`${staticImageConfig.imageHotspotField}.`,'') : null;
			const obj = {
				"id": l.id,
				"title": t1 ? ModelPropertiesParser(`{{${t1}}}`, l) : t1,
				"desc": t2 ? ModelPropertiesParser(`{{${t2}}}`, l) : t2,
				"shape": "poly",
				"fillColor": "#eab54d4d",
				"strokeColor": "black",
				"coords": [

				],
				"polygon": [

				]
			};
			tmp.map(t => {
				//obj.polygon.push([t[0].x, t[0].y])
				t[0] && obj.coords.push(t[0].x);
				t[0] && obj.coords.push(t[0].y)
			})
			//obj.polygon.push([arr[arr.length-1].x, arr[arr.length-1].y])
			obj.coords.push(arr[arr.length - 1].x);
			obj.coords.push(arr[arr.length - 1].y);

			if (mainAction) {
				const isRule = this.checkRule(mainAction.connector.rules, l);
				if(!isRule){
					obj.fillColor = '#a5a39f4d';
					obj['inActive'] = true;
				}
			}

			areaMap.push(obj)
		})

		if (imgUrl) {
			const image = new Image();
			image.onload = () => {
				savedData.height = image.height;
				savedData.width = image.width;
				let t = JSON.stringify(savedData)
				setTimeout(() => {
					this.loadableCanvas && this.loadableCanvas.loadSaveData(t)
				}, 500)
				this.setState({ image: image, savedData: savedData });
			};
			image.src = imgUrl;
			imageUrl = imgUrl
		}

		this.setState({ imageUrl, imageMap: areaMap, lines: lines });
	}

	checkRule(rules, workflowData) {
		let r = true;
		rules.forEach((x, i) => {
			console.log(x)
			const operator = x.operator;
			const property = x.key.property;
			const staticValue = x.key.staticValue;
			const leftModelName = x.key && x.key.typeModelName;
			let value = '';
			if( staticValue){
				value = x.key? workflowData ? ModelPropertiesParser(staticValue ,workflowData) : null :null; 
			}else{
				 value = x.key? workflowData ? workflowData[property] : null:null; 
			}
			
			switch(operator.toUpperCase()) {
				case 'NOTEQUALS':
					if (value !== x.value.staticValue) {
						r = true
					} else
						r = false;
					break;
				case 'EQUALS':
					if (value == x.value.staticValue) {
						r = true
					} else
						r = false;
					break;
			}
		})
		return r;
	}

	toggleEditModel() {
		this.setState({ toggleModel: !this.state.toggleModel })
	}

	focusCordinate(event) {
		this.setState({ focusedPoint: event.target.value })
	}

	render() {
		const { staticImageConfig } = this.props.layoutItem.itemProperties;
		const { workflow, label, workflow: { currentItem: { operation, inlineAction } }, dispatch, workflowData, change } = this.props;
		if (!staticImageConfig) return null
		const imageURL = staticImageConfig.url;
		const isImageHotspot = staticImageConfig.isImageHotspot;
		const showPopup = staticImageConfig.showPopup;
		let { imageUrl, image, imageMap, popover, lines, savedData, toggleModel, toggler, focusedPoint } = this.state;

		if (isImageHotspot && operation == 'CREATEUPDATE') {

			const h = image ? image.height : 400;
			const w = image ? image.width : 700;
			if (toggleModel && toggler) {
				const tmp = Object.assign({}, savedData)
				const togglerId = toggler.split('-');
				tmp.lines = [];
				tmp.lines.push(savedData.lines[`${togglerId[1]}`])
				tmp.lines[0].brushRadius = 1;
				tmp.lines[0].brushColor = '#9bc2df';

				if (focusedPoint) {
					const p = focusedPoint.split(',')
					console.log(p)
					tmp.lines.push({ points: [{ x: p[0], y: p[1] }, { x: p[0], y: p[1] }], brushColor: `#e51919`, brushRadius: 3 });
					let t = JSON.stringify(tmp);
					this.disableCanvas && this.disableCanvas.loadSaveData(t)
				}
			}
			const canvasTop = this.loadableCanvas && this.loadableCanvas.canvasContainer && this.loadableCanvas.canvasContainer.offsetTop;
			const canvasLeft = this.loadableCanvas && this.loadableCanvas.canvasContainer && this.loadableCanvas.canvasContainer.offsetLeft;
			return <div>
				<Button onClick={this.toggleEditModel}><i className='fa fa-edit' aria-hidden="true"></i></Button>
				<Button className='ml-2' onClick={this.setFieldValue.bind(this)}>Save</Button>
				<CanvasDraw imgSrc={`${imageUrl}`} brushRadius={1}
					canvasWidth={w}
					canvasHeight={h}
					enablePanAndZoom
					clampLinesToDocument
					lazyRadius={1}
					ref={canvasDraw => (this.loadableCanvas = canvasDraw)}

				/>
				{
					toggleModel && toggler && <CanvasDraw brushRadius={1}
						canvasWidth={w}
						canvasHeight={h}
						enablePanAndZoom
						clampLinesToDocument
						lazyRadius={1}
						hideGrid={true}
						ref={canvasDraw => (this.disableCanvas = canvasDraw)}
						disabled={true}
						immediateLoading={true}
						style={{ position: 'absolute', top: `${canvasTop}px`, left: `${canvasLeft}px`, background: 'transparent' }}
					/>
				}
				<CoordsModal toggleModal={this.toggleEditModel} showModal={this.state.toggleModel}
					handleToggler={this.handleToggler.bind(this)} toggler={this.state.toggler}
					staticImageConfig={staticImageConfig} lines={lines} change={change}
					onFocusCord={this.focusCordinate.bind(this)} />
			</div>
		}
		if (isImageHotspot && operation == 'READONE') {
			const MAP = {
				name: 'image-map',
				areas: imageMap,
			};

			return <div><ImageMapper src={imageUrl} map={MAP} onClick={(area, i, e) => {
				if (staticImageConfig.isTextualActionTriggerEnabled) {
					const mainAction = getMatchedAction(
						inlineAction,
						staticImageConfig.textualActionTriggerCode
					);
					if (mainAction && !area.inActive) {
						dispatch(triggerAction(mainAction, area.id, workflow));
					}
				}

			}} onMouseEnter={(area, i, e) => {
				showPopup && this.toggle(area)
			}} onMouseLeave={(area, i, e) => {
				showPopup && this.toggle()
			}} />
				{
					popover && (popover.title || popover.desc) && <div className='static-text-card'
						style={{ position: 'absolute', zIndex: 99, top: `${popover.center[0]}px`, left: `${popover.center[1]}px` }}>
						<div className='primaryLine'>
							{popover.title && <h4 className='primaryLineText'>{popover.title}</h4>}
							{popover.desc && <h4 className='primaryLineText'>{popover.desc}</h4>}
						</div>
					</div>
				}
			</div>
		}
		return (
			<div style={{ flexDirection: "column" }}>
				{
					label.show ? <Label>{label.text}</Label> : ''
				}
				<div className='staticImageDiv'>
					<img className='staticImage' src={imageURL} alt='StaticImage' />
				</div>
			</div>
		)
	}
}

const CoordsModal = (props) => {
	let lines = props.lines;
	const staticImageConfig = props.staticImageConfig;
	let valueField = staticImageConfig && staticImageConfig.valueField;
	valueField = valueField.replace(`${staticImageConfig.imageHotspotField}.`,'');
	return <div>
		<Modal isOpen={props.showModal} toggle={() => props.toggleModal()} >

			<ModalHeader toggle={props.toggleModal}>
				Edit Coordinates
			</ModalHeader>
			<ModalBody className='p-1 h-100' style={{ 'overflow': 'auto' }}>
				{
					lines && lines.map((l, index) => {
						let arr = ModelPropertiesParser(`{{${valueField}}}`, l);
						arr = JSON.parse(arr);
						return (
							<Card key={index} className="accordian">
								<CardHeader
									onClick={() => props.handleToggler(`accordian-${index}`)}>
									<i className={`icon mr-2 fa ${props.toggler === `accordian-${index}` ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
									{`${arr[0].x}, ${arr[0].y}`}
								</CardHeader>
								<Collapse className="collapse-body" isOpen={props.toggler === `accordian-${index}`}>
									<CardBody>
										{
											props.toggler === `accordian-${index}` ? arr.map(item => {
												return <div className='d-flex m-1'>
													<Input
														value={`${item.x}, ${item.y}`}
														onChange={(event) => console.log('')}
														onFocus={props.onFocusCord}
														type={'text'}
													/><i className='fa fa-trash mt-2 ml-1' style={{ 'color': 'red' }} aria-hidden="true"></i></div>
											}) : ""
										}
									</CardBody>
								</Collapse>
							</Card>
						)
					})
				}
			</ModalBody>

		</Modal>
	</div>
}
