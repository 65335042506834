import React, {useEffect} from 'react';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import { useEfr } from "../../utils/useEfr";


const FaceRecognition = (props) => {
  const {
    layoutItem: {
      itemProperties: { efrConfig },
    },
    workflowData
  } = props;
  const tokenField = efrConfig && efrConfig.tokenField;

  if(!tokenField) return <div>Please configure EFR</div>
  const token = ModelPropertiesParser(tokenField, workflowData);
  const { loading, data } = useEfr(token);
  return (
    <div>
      <div className='loader' id='loader'></div>
      <>
      {loading ? <h1>Processing</h1> : <h1>EFR SDK</h1>}
      {data.status != undefined && data.status == true ? (
        <>
          <h1>Success</h1>
          <img src={data.thumbnail} />
        </>
      ) : data.status == false ? (
        <h1>{data.errors[0].message}</h1>
      ) : (
        ""
      )}
    </>
    </div>
  ) ;
};

export default FaceRecognition;
